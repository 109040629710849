import React from 'react'

import {
  Text,
  Flex,
  Box,
  Image,
  Card
} from 'rebass'

import Layout from '../../components/layout'
import Container from '../../components/container'
import BottomHero from '../../components/bottomHero'

import deepDive from '../../images/deep_dive_plan.jpg'

import DeepDiveModal from '../../components/deepDiveModal'

const OneTimeDeepDivePage = () => (
  <Layout>
    <Container>
      {/* Hero */}
      <Flex flexWrap='wrap' p={3} mx={-2}>
        <Box mx='auto'>
          <Text textAlign='center' py={2} fontWeight='bold' fontSize={5}>One-Time Deep Dive</Text>
        </Box>
      </Flex>

      <Flex flexWrap='wrap' p={3} mx={-2}>
        <Box width={[1, 1/2]} p={2}>
          <Image src={deepDive} alt='Reversity one-time deep dive' />
        </Box>

        <Box width={[1, 1/2]} p={2}>
          <Text>Custom analysis of your diet and tailored recommendations.</Text>

          <Box py={3}>
            <Text color='gray' fontWeight='bold' py={1}>$149.99</Text>
            <DeepDiveModal />
          </Box>
        </Box>
      </Flex>
    </Container>

    <Box bg='lightGray' py={5}>
      <Container>
        <Flex flexWrap='wrap' p={3} mx={-2}>
          <Box p={2} width={1}>
            <Card
              p={3}
              variant='primary'
              borderRadius={5}
              color='gray'
              bg='white'>
              Detailed analysis of your baseline diet. Use your phone to snap pictures of your meals and snacks, and we will do the rest on our end. We will identify your current macronutrient ratio, omega 6:3 ratio, and provide a detailed qualitative assessment of the foods in your typical diet. In addition to photos, we will use web/mobile surveys to understand your dietary habits, likes, and dislikes.
            </Card>
          </Box>
          <Box p={2} width={1}>
            <Card
              p={3}
              variant='primary'
              borderRadius={5}
              color='gray'
              bg='white'>
              Personalized recommendations. We will provide specific nutritional recommendations to meet your health goals, and translate those recommendations into a detailed set of action plans.
            </Card>
          </Box>
          <Box py={2} mx='auto'>
            <DeepDiveModal />
          </Box>
        </Flex>
      </Container>
    </Box>
    <BottomHero />
  </Layout>
)

export default OneTimeDeepDivePage
