import React from 'react'
import Rodal from 'rodal'
import 'rodal/lib/rodal.css'

import { Elements } from 'react-stripe-elements'

import {
  Box,
  Button,
  Heading,
  Text
} from 'rebass'

import PaymentForm from './paymentForm'

class DeepDiveModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      visible: false,
    }
    this.hide = this.hide.bind(this)
    this.show = this.show.bind(this)
  }

  show() {
    this.setState({ visible: true })
  }

  hide() {
    this.setState({ visible: false })
  }

  render() {
    return (
      <div>
        <Button
          variant='primary'
          onClick={this.show}>
          Start your deep dive
        </Button>
        <Rodal
          visible={this.state.visible}
          onClose={this.hide}
          closeOnEsc={true}
          animation='slideDown'>
          <Box style={{ textAlign: 'left' }}>
            <Heading>Reversity Deep Dive</Heading>
            <Text color='gray' fontWeight='bold'>$149.99</Text>
            <Text color='gray' py={2} fontSize={1}>You will receive a one-time, custom analysis of your diet and tailored recommendations by Reversity.</Text>
            <Elements>
              <PaymentForm modal={this} planName='single_deep_dive' />
            </Elements>
          </Box>
        </Rodal>
      </div>
    )
  }
}

export default DeepDiveModal
